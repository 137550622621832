import { AxiosError } from "axios";
import { useState, useEffect  } from "react";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { login } from '../../Services/ApiService';
import {useIsAuthenticated} from 'react-auth-kit';
import './Login.css'
import jwtDecode from 'jwt-decode';

interface DecodedToken {
  exp: number;
}

function Login(props: any) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const signIn = useSignIn();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    setIsLoading(true);

    try {
      const response = await login(username, password);

      const expiryDateInSeconds = jwtDecode<DecodedToken>(response.access_token).exp;
      const currentDateInSeconds = Math.floor(Date.now() / 1000);
      const expiresIn = (expiryDateInSeconds - currentDateInSeconds) / 60;

      signIn({
        token: response.access_token,
        expiresIn: expiresIn,
        tokenType: "Bearer",
        authState: { username: username },
      });

      console.log('logged in')
      setIsLoading(false);
      navigate("/");
    } catch (err) {
      setIsLoading(false);
      if (err && err instanceof AxiosError)
        setError(err.response?.data.message);
      else if (err && err instanceof Error) setError(err.message);

      console.log("Error: ", err);
    }
  };

  return (
    <div className="background">
      <div className="shape"></div>
      <div className="shape"></div>
      <form onSubmit={onSubmit} className="loginForm">
        <h3>Login</h3>

        <label htmlFor="username" className="loginLabel">Username</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="loginInput"
        />

        <label htmlFor="password" className="loginLabel">Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="loginInput"
        />

        <button type="submit" disabled={isLoading} className="loginButton">
          {isLoading ? "Loading..." : "Log In"}
        </button>
        <span className="errorText">{error}</span>
      </form>
    </div>
  );
}

export { Login };

import axios, { AxiosError} from "axios";

const API_BASE_URL = '/vectors';
const NOT_FOUND_STATUS = 404
const headers = { "Content-Type": "application/json" }

export interface Note {
    uuid: React.Key;
    title: string;
    text: string;
}

export interface NoteRequestDTO {
    title: string;
    text: string;
}

export const login = async (username: string, password: string) => {
    const response = await axios.post("/api/v1/login", {
        username,
        password,
    });        
        
    console.log(response)
    return response.data
}

export const getNotes = async () => {
    try {
        const response = await axios.get(API_BASE_URL, { withCredentials: true });
        return response.data
    } catch (error) {
        if ((error as AxiosError).response?.status !== NOT_FOUND_STATUS) {
            throw new Error("There has been problem with getting your notes, please try again later.")
        }
        return []
    }
}

export const addNote = async (note: NoteRequestDTO) => {
    try {
        const response = await axios.post(API_BASE_URL, note, { headers: headers, withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error("There has been problem while saving your note, please try again later.")
    }
}

export const updateNote = async (id: string, note: NoteRequestDTO) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/${id}`, note, { headers: headers, withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error("There has been problem while updating your note, please try again later.");
    }
}

export const deleteNote = async (id: string) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/${id}`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error("There has been problem while deleting your note, please try again later.");
    }
}

import React from 'react'
import './Header.css'
import '../../App.css'
import { useNavigate} from 'react-router-dom'
import ThemeToggleSwitch from '../ThemeToggleSwitch/ThemeToggleSwitch'
import { useSignOut } from 'react-auth-kit'

function Header() {
  const signOut = useSignOut();
  const navigate = useNavigate();

  const logout = () => {
    signOut();
    navigate("/login");
  }

  return (
    <nav className='flex justify-content-center align-items-center'>
        <button onClick={logout} className="logoutButton">Log out</button>
        <h1 className='navTitle margin-l-auto'>Perlan Chatbot knowledge base</h1>
        <ThemeToggleSwitch></ThemeToggleSwitch>
    </nav>
  )
}

export default Header
import './App.css';
import ExistingNotesSection from './Components/ExistingNotesSection/ExistingNotesSection';
import Header from './Components/Header/Header';
import { ThemeProvider } from './Components/ThemeProvider/ThemeContext';
import { Login } from "./Components/Login/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth loginPath="/login">
                <ThemeProvider>
                  <Header></Header>
                  <ExistingNotesSection/>
                </ThemeProvider>
              </RequireAuth>
            }
          ></Route>
          <Route path="/login" element={<Login />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
